import { RouterProvider } from 'react-router-dom';
import { siteRouting } from '../../routing';
import PageTemplate from './page/page.js';

export default function App() {
  // Get site routing settings
  const router = siteRouting();
  return (
    <div id="app">
      <PageTemplate>
        <RouterProvider router={router} />
      </PageTemplate>
    </div>
  );
}
