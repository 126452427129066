export default function IndexContent() {
  return (
    <>
      <h1>{process.env.REACT_APP_SITE_TITLE}</h1>
      <p className="font-extralight text-xl mb-8">
        Taloushallinnon asiantuntijapalvelut
      </p>
      <p>Apollonkatu, Helsinki</p>
      <p>Puutarhakatu, Turku</p>
      <p className="break-all sm:break-words">sanna.tuominen@controllersturku.fi</p>
    </>
  );
}
