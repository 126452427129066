import { createBrowserRouter } from 'react-router-dom';
import IndexContent from './routes/index-content';
// import ErrorPage from './routes/error-page';

const siteRouting = () => {
  return createBrowserRouter([
    // {
    //   path: '/',
    //   element: <IndexContent />,
    //   errorElement: <ErrorPage />,
    // },
    /**
     * We are using simple non-routing setting for now:
     */
    {
      path: '*',
      element: <IndexContent />,
      // errorElement: <ErrorPage />,
    },
    // {
    //   path: '/my-custom-path',
    //   element: <IndexContent />,
    //   errorElement: <ErrorPage />,
    // },
  ]);
}

export { siteRouting };
