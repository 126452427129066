import ContentBox from "../../components/content-box/content-box.js";

function PageTemplate({ children }) {
  return (
    <div id="main">
      <ContentBox>
        {children}
      </ContentBox>
    </div>
  );
}

export default PageTemplate;
