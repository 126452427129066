import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './views/templates/app';

/**
 * The HTML template is populated with some general content for the crawlers.
 * Remove this content before populating the page with actual content.
 */
const nonJSContentEl = document.getElementById('non-js-content');
if (nonJSContentEl) {
  nonJSContentEl.remove();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
